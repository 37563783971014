import { IkomuninicareBoard } from '../types';

const bodyPartsBoard: IkomuninicareBoard = {
  id: 'bodyParts',
  tile: {
    labelKey: 'symbol.komunicare.bodyParts',
    image: '/symbols/komunicare/cover/partes_del_cuerpo.svg',
    loadBoard: 'bodyPartsBoard',
    sound: '',
    type: 'folder',
    backgroundColor: '#2196F3',
    linkedBoard: false,
    id: 'bodyParts'
  },
  board: {
    isPublic: false,
    tiles: [
      {
        label: 'boca',
        labelKey: 'symbol.komunicare.bodyParts.boca',
        image: '/symbols/komunicare/bodyParts/boca.svg',
        id: 'bodyPart1',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'cara',
        labelKey: 'symbol.komunicare.bodyParts.cara',
        image: '/symbols/komunicare/bodyParts/cara.svg',
        id: 'bodyPart2',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'dedos',
        labelKey: 'symbol.komunicare.bodyParts.dedos',
        image: '/symbols/komunicare/bodyParts/dedos.svg',
        id: 'bodyPart3',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'garganta',
        labelKey: 'symbol.komunicare.bodyParts.garganta',
        image: '/symbols/komunicare/bodyParts/garganta.svg',
        id: 'bodyPart4',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'manos',
        labelKey: 'symbol.komunicare.bodyParts.manos',
        image: '/symbols/komunicare/bodyParts/manos.svg',
        id: 'bodyPart5',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'mejilla',
        labelKey: 'symbol.komunicare.bodyParts.mejilla',
        image: '/symbols/komunicare/bodyParts/mejilla.svg',
        id: 'bodyPart6',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'ojo',
        labelKey: 'symbol.komunicare.bodyParts.ojo',
        image: '/symbols/komunicare/bodyParts/ojo.svg',
        id: 'bodyPart7',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'piernas',
        labelKey: 'symbol.komunicare.bodyParts.piernas',
        image: '/symbols/komunicare/bodyParts/piernas.svg',
        id: 'bodyPart8',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'brazos',
        labelKey: 'symbol.komunicare.bodyParts.brazos',
        image: '/symbols/komunicare/bodyParts/brazos.svg',
        id: 'bodyPart9',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'cola',
        labelKey: 'symbol.komunicare.bodyParts.cola',
        image: '/symbols/komunicare/bodyParts/cola.svg',
        id: 'bodyPart10',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'dientes',
        labelKey: 'symbol.komunicare.bodyParts.dientes',
        image: '/symbols/komunicare/bodyParts/dientes.svg',
        id: 'bodyPart11',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'hombros',
        labelKey: 'symbol.komunicare.bodyParts.hombros',
        image: '/symbols/komunicare/bodyParts/hombros.svg',
        id: 'bodyPart12',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'me duele',
        labelKey: 'symbol.komunicare.bodyParts.me_duele',
        image: '/symbols/komunicare/bodyParts/me_duele.svg',
        id: 'bodyPart13',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'menton',
        labelKey: 'symbol.komunicare.bodyParts.menton',
        image: '/symbols/komunicare/bodyParts/menton.svg',
        id: 'bodyPart14',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'oreja',
        labelKey: 'symbol.komunicare.bodyParts.oreja',
        image: '/symbols/komunicare/bodyParts/oreja.svg',
        id: 'bodyPart15',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'pies',
        labelKey: 'symbol.komunicare.bodyParts.pies',
        image: '/symbols/komunicare/bodyParts/pies.svg',
        id: 'bodyPart16',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'cabeza',
        labelKey: 'symbol.komunicare.bodyParts.cabeza',
        image: '/symbols/komunicare/bodyParts/cabeza.svg',
        id: 'bodyPart17',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'cuello',
        labelKey: 'symbol.komunicare.bodyParts.cuello',
        image: '/symbols/komunicare/bodyParts/cuello.svg',
        id: 'bodyPart18',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'espalda',
        labelKey: 'symbol.komunicare.bodyParts.espalda',
        image: '/symbols/komunicare/bodyParts/espalda.svg',
        id: 'bodyPart19',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'lengua',
        labelKey: 'symbol.komunicare.bodyParts.lengua',
        image: '/symbols/komunicare/bodyParts/lengua.svg',
        id: 'bodyPart20',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'me pica',
        labelKey: 'symbol.komunicare.bodyParts.me_pica',
        image: '/symbols/komunicare/bodyParts/me_pica.svg',
        id: 'bodyPart21',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'nariz',
        labelKey: 'symbol.komunicare.bodyParts.nariz',
        image: '/symbols/komunicare/bodyParts/nariz.svg',
        id: 'bodyPart22',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'panza',
        labelKey: 'symbol.komunicare.bodyParts.panza',
        image: '/symbols/komunicare/bodyParts/panza.svg',
        id: 'bodyPart23',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'rodilla',
        labelKey: 'symbol.komunicare.bodyParts.rodilla',
        image: '/symbols/komunicare/bodyParts/rodilla.svg',
        id: 'bodyPart24',
        backgroundColor: 'rgb(187, 222, 251)'
      }
    ],
    id: 'bodyPartsBoard',
    locale: 'es-ES',
    format: 'cboard',
    description: '',
    isFixed: true,
    nameKey: 'symbol.komunicare.bodyPartsTitle',
    author: 'Komunicare',
    email: 'info@komuni.care',
    caption: '/symbols/komunicare/cover/bodyParts.svg',
    grid: {
      rows: 5,
      columns: 5,
      order: []
    }
  }
};

export { bodyPartsBoard };
