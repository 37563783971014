import { feelingsBoard } from './komunicare/feelingsBoard';
import { chatBoard } from './komunicare/chatBoard';
import { foodBoard } from './komunicare/foodBoard';
import { drinksBoard } from './komunicare/drinksBoards';
import { familyBoard } from './komunicare/familyBoard';
import { sportsBoard } from './komunicare/sportsBoard';
import { Board } from './types';
import { fruitsAndVegetables } from './komunicare/fruitsAndVegetables';
import { breakfastBoard } from './komunicare/breakfastsBoard';
import { clothingBoard } from './komunicare/clothingBoard';
import { accesoriesBoard } from './komunicare/accesoriesBoard';
import { hobbiesBoard } from './komunicare/hobbiesBoard';
import { personalHygiene } from './komunicare/personalHygiene';
import { schoolBoard } from './komunicare/schoolBoard';
import { handWashingBoard } from './komunicare/handWashingBoard';
import { toothBrushingBoard } from './komunicare/toothBrushingBoard';
import { bodyPartsBoard } from './komunicare/bodyPartsBoards';
import { actionsBoard } from './komunicare/actionsBoard';
import { toysBoard } from './komunicare/toysBoard';
import { questionsAndConversationBoard } from './komunicare/questionsAndConversationBoard';

const komunicare: Board[] = [
  {
    isPublic: true,
    tiles: [
      feelingsBoard.tile,
      chatBoard.tile,
      foodBoard.tile,
      drinksBoard.tile,
      familyBoard.tile,
      sportsBoard.tile,
      clothingBoard.tile,
      hobbiesBoard.tile,
      personalHygiene.tile,
      schoolBoard.tile,
      bodyPartsBoard.tile,
      actionsBoard.tile,
      toysBoard.tile,
      questionsAndConversationBoard.tile
    ],
    locale: 'es-ES',
    format: 'cboard',
    description: 'komunicareDescription',
    isFixed: true,
    name: 'Tablero Komunicare',
    author: 'Komunicare',
    email: 'info@komuni.care',
    caption: '../logo.svg',
    grid: {
      rows: 4,
      columns: 4,
      order: [
        [feelingsBoard.id, chatBoard.id, foodBoard.id, bodyPartsBoard.id],
        [drinksBoard.id, familyBoard.id, sportsBoard.id, actionsBoard.id],
        [clothingBoard.id, hobbiesBoard.id, personalHygiene.id, schoolBoard.id],
        [toysBoard.id, questionsAndConversationBoard.id, null, null]
      ]
    },
    lastEdited: '2024-06-12T17:09:50-03:00',
    id: 'komunicare',
    hidden: false
  },
  feelingsBoard.board,
  chatBoard.board,
  foodBoard.board,
  drinksBoard.board,
  familyBoard.board,
  sportsBoard.board,
  fruitsAndVegetables.board,
  breakfastBoard.board,
  clothingBoard.board,
  accesoriesBoard.board,
  hobbiesBoard.board,
  personalHygiene.board,
  handWashingBoard.board,
  schoolBoard.board,
  toothBrushingBoard.board,
  bodyPartsBoard.board,
  actionsBoard.board,
  toysBoard.board,
  questionsAndConversationBoard.board
];

export default komunicare;
