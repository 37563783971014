import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'cboard.components.PhraseShare.title',
    defaultMessage: 'Share or copy your Phrase'
  },
  close: {
    id: 'cboard.components.PhraseShare.close',
    defaultMessage: 'Close'
  },
  copyLink: {
    id: 'cboard.components.PhraseShare.copyPhrase',
    defaultMessage: 'Copy phrase'
  },
  facebook: {
    id: 'cboard.components.PhraseShare.facebook',
    defaultMessage: 'Facebook'
  },
  twitter: {
    id: 'cboard.components.PhraseShare.twitter',
    defaultMessage: 'Twitter'
  },
  email: {
    id: 'cboard.components.PhraseShare.email',
    defaultMessage: 'Email'
  },
  subject: {
    id: 'cboard.components.PhraseShare.subject',
    defaultMessage: 'Check out this email from Cboard user!'
  },
  whatsapp: {
    id: 'cboard.components.PhraseShare.whatsapp',
    defaultMessage: 'Whatsapp'
  },
  reddit: {
    id: 'cboard.components.PhraseShare.reddit',
    defaultMessage: 'Reddit'
  }
});
