import React, { FC } from 'react';
import classNames from 'classnames';
// @ts-ignore
import { Scannable } from 'react-scannable';

import './Tile.css';

interface TileProps {
  backgroundColor?: string;
  borderColor?: string;
  children?: React.ReactNode;
  className?: string;
  variant?: 'button' | 'folder' | 'board';
}

const Tile: FC<TileProps> = ({
   backgroundColor,
   borderColor,
   children,
   className: classNameProp,
   variant,
   ...other
 }) => {
  const folder = variant === 'folder';
  const className = classNames('Tile', classNameProp, {
    'Tile--folder': folder
  });

  const tileShapeClassName = classNames('TileShape', {
    'TileShape--folder': folder
  });

  const tileShapeStyles: React.CSSProperties = {};

  if (borderColor) {
    tileShapeStyles.borderColor = borderColor;
  }

  if (backgroundColor) {
    tileShapeStyles.backgroundColor = backgroundColor;
  }

  const onSelect = (event: React.MouseEvent, scannable: any, scanner: any) => {
    if (folder) {
      scanner.reset();
    }
  };

  return (
    <Scannable onSelect={onSelect} id={'scannable'}>
      <button className={className} type="button" {...other}>
        <div className={tileShapeClassName} style={tileShapeStyles} />
        {children}
      </button>
    </Scannable>
  );
};

export default Tile;
