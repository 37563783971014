import axios, { AxiosInstance } from 'axios';
import { getAuthToken } from './api';

let improvePhraseAbortController: AbortController | null = null;

export class PhraseImprover {
  private axiosInstance: AxiosInstance;

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  async improvePhrase({ phrase, language }: { phrase: string; language: string }): Promise<string> {
    const authToken = getAuthToken();
    if (!(authToken && authToken.length)) {
      console.error('Need to be authenticated to perform this request');
      return '';
    }

    const headers = {
      Authorization: `Bearer ${authToken}`,
    };

    improvePhraseAbortController = new AbortController();

    try {
      const response = await this.axiosInstance.post(
        `/gpt/edit`,
        { phrase, language },
        {
          headers,
          signal: improvePhraseAbortController.signal,
        }
      );
      return JSON.stringify(response.data.phrase);
    } catch (error) {
      if (axios.isCancel(error)) {
        console.error('Request canceled', error.message);
      } else if (!navigator.onLine) {
        console.error('No internet connection');
        return JSON.stringify('No internet connection');
      } else {
        console.error(error);
      }
      return '';
    }
  }
}

export { improvePhraseAbortController };
