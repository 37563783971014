export const drinksBoard = {
  id: 'drinks',
  tile: {
    label: 'Bebidas',
    labelKey: 'symbol.komunicare.drinks',
    image: '/symbols/komunicare/cover/bebidas.svg',
    loadBoard: 'drinksBoard',
    sound: '',
    type: 'folder',
    backgroundColor: '#2196F3',
    linkedBoard: false,
    id: 'drinks'
  },
  board: {
    isPublic: false,
    tiles: [
      {
        label: 'agua',
        labelKey: 'symbol.komunicare.food.drinks.water',
        image: '/symbols/komunicare/drinks/agua.svg',
        id: 'water',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'cafe',
        labelKey: 'symbol.komunicare.food.drinks.coffee',
        image: '/symbols/komunicare/drinks/cafe.svg',
        id: 'coffee',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'cerveza',
        labelKey: 'symbol.komunicare.food.drinks.beer',
        image: '/symbols/komunicare/drinks/cerveza.svg',
        id: 'beer',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'chocolatada',
        labelKey: 'symbol.komunicare.food.drinks.chocolateMilk',
        image: '/symbols/komunicare/drinks/chocolatada.svg',
        id: 'chocolateMilk',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'coca-cola',
        labelKey: 'symbol.komunicare.food.drinks.coke',
        image: '/symbols/komunicare/drinks/cocacola.svg',
        id: 'coke',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'gaseosas',
        labelKey: 'symbol.komunicare.food.drinks.softDrinks',
        image: '/symbols/komunicare/drinks/gaseosas.svg',
        id: 'softDrinks',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'jugo de frutas',
        labelKey: 'symbol.komunicare.food.drinks.fruitJuice',
        image: '/symbols/komunicare/drinks/jugo_de_frutas.svg',
        id: 'fruitJuice',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'jugo de naranja',
        labelKey: 'symbol.komunicare.food.drinks.orangeJuice',
        image: '/symbols/komunicare/drinks/jugo_de_naranja.svg',
        id: 'orangeJuice',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'leche',
        labelKey: 'symbol.komunicare.food.drinks.milk',
        image: '/symbols/komunicare/drinks/leche.svg',
        id: 'milk',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'te',
        labelKey: 'symbol.komunicare.food.drinks.tea',
        image: '/symbols/komunicare/drinks/te.svg',
        id: 'te',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'licuado',
        labelKey: 'symbol.komunicare.food.drinks.smoothie',
        image: '/symbols/komunicare/drinks/licuado.svg',
        id: 'smoothie',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'limonada',
        labelKey: 'symbol.komunicare.food.drinks.lemonade',
        image: '/symbols/komunicare/drinks/limonada.svg',
        id: 'lemonade',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'soda',
        labelKey: 'symbol.komunicare.food.drinks.soda',
        image: '/symbols/komunicare/drinks/soda.svg',
        id: 'soda',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'submarino',
        labelKey: 'symbol.komunicare.food.drinks.submarine',
        image: '/symbols/komunicare/drinks/submarino.svg',
        id: 'submarine',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'vino',
        labelKey: 'symbol.komunicare.food.drinks.wine',
        image: '/symbols/komunicare/drinks/vino.svg',
        id: 'wine',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'yo quiero',
        labelKey: 'symbol.komunicare.food.drinks.IWant',
        image: '/symbols/komunicare/yo_quiero.svg',
        id: 'IWant',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'no me gusta',
        labelKey: 'symbol.komunicare.food.drinks.dontLike',
        image: '/symbols/komunicare/no_me_gusta.svg',
        id: 'dontLike',
        backgroundColor: 'rgb(187, 222, 251)'
      }
    ],
    id: 'drinksBoard',
    locale: 'es-ES',
    format: 'cboard',
    description: '',
    isFixed: true,
    nameKey: 'symbol.komunicare.food.drinks',
    author: 'Komunicare',
    email: 'info@komuni.care',
    caption: '/symbols/komunicare/cover/bebidas.svg',
    grid: {
      rows: 4,
      columns: 5,
      order: [
        ['water', 'coffee', 'tea', 'milk', 'juice'],
        ['lemonade', 'soda', 'submarine', 'wine'],
        ['dontLike', 'IWant', null, null, null],
        [null, null, null, null, null, null]
      ]
    }
  }
};
