export const feelingsBoard = {
  id: 'feelings',
  tile: {
    labelKey: 'symbol.komunicare.feelings',
    image: '/symbols/komunicare/cover/emociones.svg',
    loadBoard: 'feelingsBoard',
    sound: '',
    type: 'folder',
    backgroundColor: '#2196F3',
    linkedBoard: false,
    id: 'feelings'
  },
  board: {
    isPublic: false,
    tiles: [
      {
        label: 'asustado',
        labelKey: 'symbol.komunicare.feelings.scared',
        image: '/symbols/komunicare/feelings/asustado.svg',
        id: 'scared',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'cansado',
        labelKey: 'symbol.komunicare.feelings.tired',
        image: '/symbols/komunicare/feelings/cansado.svg',
        id: 'tired',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'contento',
        labelKey: 'symbol.komunicare.feelings.happy',
        image: '/symbols/komunicare/feelings/contento.svg',
        id: 'happy',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'enfermo',
        labelKey: 'symbol.komunicare.feelings.sick',
        image: '/symbols/komunicare/feelings/enfermo.svg',
        id: 'sick',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'enojado',
        labelKey: 'symbol.komunicare.feelings.angry',
        image: '/symbols/komunicare/feelings/enojado.svg',
        id: 'angry',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'triste',
        labelKey: 'symbol.komunicare.feelings.sad',
        image: '/symbols/komunicare/feelings/triste.svg',
        id: 'sad',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'vos',
        labelKey: 'symbol.komunicare.feelings.you',
        image: '/symbols/komunicare/feelings/vos_nina.svg',
        id: 'you_f',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'vos',
        labelKey: 'symbol.komunicare.feelings.you',
        image: '/symbols/komunicare/feelings/vos_nino.svg',
        id: 'you_m',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'yo',
        labelKey: 'symbol.komunicare.feelings.me',
        image: '/symbols/komunicare/feelings/yo_nino.svg',
        id: 'me_m',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'yo',
        labelKey: 'symbol.komunicare.feelings.me',
        image: '/symbols/komunicare/feelings/yo_nina.svg',
        id: 'me_f',
        backgroundColor: 'rgb(187, 222, 251)'
      }
    ],
    id: 'feelingsBoard',
    locale: 'es-ES',
    format: 'cboard',
    description: '',
    isFixed: true,
    nameKey: 'symbol.komunicare.feelings',
    author: 'Komunicare',
    email: 'info@komuni.care',
    caption: '/symbols/komunicare/cover/emociones.svg',
    grid: {
      rows: 3,
      columns: 4,
      order: [
        ['you_f', 'you_m', 'me_m', 'me_f'],
        ['scared', 'tired', 'happy', 'sick'],
        ['angry', 'sad', null, null]
      ]
    }
  }
};
