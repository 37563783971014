import { IkomuninicareBoard } from '../types';

export const schoolBoard: IkomuninicareBoard = {
  id: 'schoolBoard',
  tile: {
    labelKey: 'symbol.komunicare.schoolBoard',
    image: '/symbols/komunicare/cover/escuela.svg',
    loadBoard: 'schoolBoard',
    sound: '',
    type: 'folder',
    backgroundColor: '#2196F3',
    linkedBoard: false,
    id: 'school'
  },
  board: {
    isPublic: false,
    tiles: [
      {
        label: 'aula',
        labelKey: 'symbol.komunicare.school.classroom',
        image: '/symbols/komunicare/schoolPictograms/aula.svg',
        id: 'classroom',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'calculadora',
        labelKey: 'symbol.komunicare.school.calculator',
        image: '/symbols/komunicare/schoolPictograms/calculadora.svg',
        id: 'calculator',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'carpeta',
        labelKey: 'symbol.komunicare.school.folder',
        image: '/symbols/komunicare/schoolPictograms/carpeta.svg',
        id: 'folder',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'cartuchera',
        labelKey: 'symbol.komunicare.school.pencilCase',
        image: '/symbols/komunicare/schoolPictograms/cartuchera.svg',
        id: 'pencilCase',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'compañeros',
        labelKey: 'symbol.komunicare.school.classmates',
        image: '/symbols/komunicare/schoolPictograms/companeros.svg',
        id: 'classmates',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'cuaderno',
        labelKey: 'symbol.komunicare.school.notebook',
        image: '/symbols/komunicare/schoolPictograms/cuaderno.svg',
        id: 'notebook',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'goma',
        labelKey: 'symbol.komunicare.school.eraser',
        image: '/symbols/komunicare/schoolPictograms/goma.svg',
        id: 'eraser',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'lápices de colores',
        labelKey: 'symbol.komunicare.school.coloredPencils',
        image: '/symbols/komunicare/schoolPictograms/lapices_de_colores.svg',
        id: 'coloredPencils',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'lápiz',
        labelKey: 'symbol.komunicare.school.pencil',
        image: '/symbols/komunicare/schoolPictograms/lapiz.svg',
        id: 'pencil',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'maestra',
        labelKey: 'symbol.komunicare.school.teacher',
        image: '/symbols/komunicare/schoolPictograms/maestra.svg',
        id: 'teacher',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'mochila',
        labelKey: 'symbol.komunicare.school.backpack',
        image: '/symbols/komunicare/schoolPictograms/mochila.svg',
        id: 'backpack',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'pizarrón',
        labelKey: 'symbol.komunicare.school.blackboard',
        image: '/symbols/komunicare/schoolPictograms/pizarron.svg',
        id: 'blackboard',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'plasticola',
        labelKey: 'symbol.komunicare.school.glue',
        image: '/symbols/komunicare/schoolPictograms/plasticola.svg',
        id: 'glue',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'recreo',
        labelKey: 'symbol.komunicare.school.recess',
        image: '/symbols/komunicare/schoolPictograms/recreo.svg',
        id: 'recess',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'regla',
        labelKey: 'symbol.komunicare.school.ruler',
        image: '/symbols/komunicare/schoolPictograms/regla.svg',
        id: 'ruler',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'sacapuntas',
        labelKey: 'symbol.komunicare.school.sharpener',
        image: '/symbols/komunicare/schoolPictograms/sacapuntas.svg',
        id: 'sharpener',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'tijera',
        labelKey: 'symbol.komunicare.school.scissors',
        image: '/symbols/komunicare/schoolPictograms/tijera.svg',
        id: 'scissors',
        backgroundColor: 'rgb(187, 222, 251)'
      }
    ],
    id: 'schoolBoard',
    locale: 'es-ES',
    format: 'cboard',
    description: '',
    isFixed: true,
    nameKey: 'symbol.komunicare.school',
    author: 'Komunicare',
    email: 'info@komuni.care',
    caption: '/symbols/komunicare/cover/escuela.svg',
    grid: {
      rows: 4,
      columns: 5,
      order: []
    }
  }
};
