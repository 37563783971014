import boards from './api/boards.json';
import picSeePal from './api/corePicSeePal.json';
import komunicare from './api/komunicare';

interface Boards {
  komunicare: typeof komunicare;
  advanced: any;
  picSeePal: any;
}

export const DEFAULT_BOARDS: Boards = {
  komunicare: komunicare,
  advanced: boards.advanced,
  picSeePal: picSeePal,
};

export const dataURLtoFile = (
    dataurl: string,
  filename: string,
  checkExtension = false
): File => {
  // https://stackoverflow.com/a/38936042
  const arr = dataurl.split(',');
  if (arr.length !== 2) {
    throw new Error('Formato de Data URL inválido');
  }

  const mimeMatch = arr[0].match(/:(.*?);/);
  if (!mimeMatch || mimeMatch.length < 2) {
    throw new Error('No se pudo extraer el tipo MIME de la Data URL');
  }
  const type = mimeMatch[1];

  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  let name = filename;
  if (checkExtension) {
    const extension = type.split('/')[1].toLowerCase();
    name = `${name}.${extension}`;
  }

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], name, { type });
};

export const convertImageUrlToCatchable = (imageUrl: string): string | null => {
  const CBOARD_PRODUCTION_BLOB_CONTAINER_HOSTNAME = 'komunicare.blob.core.windows.net';
  const PROTOCOL_LENGTH = 8; // Corrección del nombre de la variable
  const isCboardProductionBlobContainer = imageUrl.startsWith(
    CBOARD_PRODUCTION_BLOB_CONTAINER_HOSTNAME,
    PROTOCOL_LENGTH
  );

  const cboardBlobUsingCDN = (url: string): string => {
    const CDN_HOSTNAME = 'komunicareprod.azureedge.net';
    return url.replace(CBOARD_PRODUCTION_BLOB_CONTAINER_HOSTNAME, CDN_HOSTNAME);
  };

  if (isCboardProductionBlobContainer) return cboardBlobUsingCDN(imageUrl);
  return null;
};
