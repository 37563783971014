import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FormattedMessage } from 'react-intl';
import CopyIcon from '@material-ui/icons/FilterNone';
import CloseIcon from '@material-ui/icons/Close';
import ShareButton from '../SymbolOutput/ShareButton';
import IconButton from '../../../UI/IconButton';
import Button from '@material-ui/core/Button';
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  WhatsappShareButton,
  WhatsappIcon,
  RedditShareButton,
  RedditIcon
} from 'react-share';
import messages from './PhraseShare.messages';
import './PhraseShare.css';
import PremiumFeature from '../../../PremiumFeature';
import { connect } from 'react-redux';

const PhraseShare = ({
  label,
  phrase,
  intl,
  open,
  fullScreen,
  onShareClick,
  onShareClose,
  onCopyPhrase,
  style,
  hidden,
  increaseOutputButtons,
  improvedPhrase
}) => {
  const sanitizePhrase = phrase => {
    return phrase ? phrase.replace(/["“”]/g, '') : ''; // Remove all types of quotation marks
  };

  return (
    <React.Fragment>
      <ShareButton
        label={label}
        color="inherit"
        onClick={onShareClick}
        style={style}
        hidden={hidden}
        className={
          increaseOutputButtons ? 'Output__button__lg' : 'Output__button__sm'
        }
      />
      <Dialog
        open={open}
        onClose={onShareClose}
        fullScreen={fullScreen}
        className="SharePhraseDialog__container"
      >
        <DialogTitle className="SharePhraseDialog__title">
          <FormattedMessage {...messages.title} />

          <IconButton
            label={intl.formatMessage(messages.close)}
            onClick={onShareClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="SharePhraseDialog__content">
          <PremiumFeature>
            <div className="SharePhraseDialog__socialIcons">
              <Button
                onClick={() => onCopyPhrase(sanitizePhrase(improvedPhrase))}
                color="primary"
              >
                <div className="SharePhraseDialog__socialIcons__copyAction">
                  <div>
                    <CopyIcon />
                  </div>
                  <FormattedMessage {...messages.copyLink} />
                </div>
              </Button>
              <Button>
                <EmailShareButton
                  subject={intl.formatMessage(messages.subject)}
                  body={sanitizePhrase(improvedPhrase)}
                >
                  <EmailIcon round />
                  <FormattedMessage id="email" {...messages.email} />
                </EmailShareButton>
              </Button>
              <Button>
                <FacebookShareButton
                  url={'https://komuni.care'}
                  quote={sanitizePhrase(improvedPhrase)}
                  onShareWindowClose={onShareClose}
                >
                  <FacebookIcon round />
                  <FormattedMessage id="facebook" {...messages.facebook} />
                </FacebookShareButton>
              </Button>
              <Button>
                <TwitterShareButton
                  hashtags={['komunicare', 'AAC']}
                  via={'komunicare'}
                  url={sanitizePhrase(improvedPhrase)}
                  onShareWindowClose={onShareClose}
                >
                  <TwitterIcon round />
                  <FormattedMessage id="twitter" {...messages.twitter} />
                </TwitterShareButton>
              </Button>
              <Button>
                <WhatsappShareButton url={sanitizePhrase(improvedPhrase)}>
                  <WhatsappIcon round />
                  <FormattedMessage id="whatsapp" {...messages.whatsapp} />
                </WhatsappShareButton>
              </Button>
              <Button>
                <RedditShareButton
                  title={sanitizePhrase(improvedPhrase)}
                  url={'https://komuni.care'}
                  onShareWindowClose={onShareClose}
                >
                  <RedditIcon round />
                  <FormattedMessage id="reddit" {...messages.reddit} />
                </RedditShareButton>
              </Button>
            </div>
          </PremiumFeature>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

PhraseShare.defaultProps = {
  open: false,
  disabled: false,
  onShareClose: () => {},
  onCopyPhrase: () => {}
};

PhraseShare.propTypes = {
  open: PropTypes.bool,
  phrase: PropTypes.string.isRequired,
  onShareClose: PropTypes.func,
  onShareClick: PropTypes.func.isRequired,
  onCopyPhrase: PropTypes.func,
  improvedPhrase: PropTypes.string
};

const mapStateToProps = state => ({
  improvedPhrase: state.board.improvedPhrase
});

export default connect(mapStateToProps)(PhraseShare);
