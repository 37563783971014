import React, { Component } from 'react';
// @ts-ignore
import { connect, ConnectedProps } from 'react-redux';
// @ts-ignore
import {FormattedMessage, injectIntl, WrappedComponentProps} from 'react-intl';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';

import messages from './WelcomeScreen.messages';
import { finishFirstVisit } from '../App/App.actions';
import Information from './Information';
import Login from '../Account/Login';
import CboardLogo from './CboardLogo/CboardLogo.component';
import './WelcomeScreen.css';

interface OwnProps {
  heading?: string;
  text?: string;
}

interface State {
  activeView: string;
}

const mapDispatchToProps = {
  finishFirstVisit
};

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & OwnProps & WrappedComponentProps;

class WelcomeScreen extends Component<Props, State> {
  state: State = {
    activeView: ''
  };

  handleActiveView = (activeView: string) => {
    this.setState({
      activeView
    });
  };

  onResetPasswordClick = () => {
    this.resetActiveView();
    this.handleActiveView('forgot');
  };

  resetActiveView = () => {
    this.setState({
      activeView: ''
    });
  };

  render() {
    const { finishFirstVisit, heading, text } = this.props;
    const { activeView } = this.state;

    return (
      <div className="WelcomeScreen">
        <div className="WelcomeScreen__container">
          <div className="WelcomeScreen__content">
            <Information heading={heading} text={text} />
          </div>
          <div className="WelcomeScreen__logo">
            <CboardLogo />
          </div>
          <footer className="WelcomeScreen__footer">
            <Button
              className="WelcomeScreen__button WelcomeScreen__button--login"
              variant="contained"
              onClick={() => this.handleActiveView('login')}
            >
              <FormattedMessage {...messages.login} />
            </Button>
          </footer>
          <div className="WelcomeScreen__links">
            <Link
              href="#"
              rel="noopener noreferrer"
              color="inherit"
            >
              <FormattedMessage {...messages.privacy} />
            </Link>
            <Link
              href="#"
              rel="noopener noreferrer"
              color="inherit"
            >
              <FormattedMessage {...messages.terms} />
            </Link>
          </div>
        </div>
        <Login
          isDialogOpen={activeView === 'login'}
          onResetPasswordClick={this.onResetPasswordClick}
          onClose={this.resetActiveView}
        />
      </div>
    );
  }
}

export default connector(injectIntl(WelcomeScreen));
