import { IkomuninicareBoard } from '../types';

export const accesoriesBoard: IkomuninicareBoard = {
  id: 'accesoriesBoard',
  tile: {
    labelKey: 'symbol.komunicare.accesories',
    image: '/symbols/komunicare/cover/ropa.svg',
    loadBoard: 'accesoriesBoard',
    sound: '',
    type: 'folder',
    backgroundColor: '#2196F3',
    linkedBoard: false,
    id: 'accesories'
  },
  board: {
    isPublic: false,
    tiles: [
      {
        label: 'gorra de sol',
        labelKey: 'symbol.komunicare.accesories.sunCap',
        image: '/symbols/komunicare/accesories/gorra_de_sol.svg',
        id: 'sunCap',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'anteojos de sol',
        labelKey: 'symbol.komunicare.accesories.sunglasses',
        image: '/symbols/komunicare/accesories/anteojos_de_sol.svg',
        id: 'sunglasses',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'cinturón',
        labelKey: 'symbol.komunicare.accesories.belt',
        image: '/symbols/komunicare/accesories/cinturon.svg',
        id: 'belt',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'guantes',
        labelKey: 'symbol.komunicare.accesories.gloves',
        image: '/symbols/komunicare/accesories/guantes.svg',
        id: 'gloves',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'anteojos de vista',
        labelKey: 'symbol.komunicare.accesories.glasses',
        image: '/symbols/komunicare/accesories/anteojos_de_vista.svg',
        id: 'glasses',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'reloj de mano',
        labelKey: 'symbol.komunicare.accesories.wristwatch',
        image: '/symbols/komunicare/accesories/reloj_de_mano.svg',
        id: 'wristwatch',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'bufanda',
        labelKey: 'symbol.komunicare.accesories.scarf',
        image: '/symbols/komunicare/accesories/bufanda.svg',
        id: 'scarf',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'gorro de lana',
        labelKey: 'symbol.komunicare.accesories.woolHat',
        image: '/symbols/komunicare/accesories/gorro_de_lana.svg',
        id: 'woolHat',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'corbata',
        labelKey: 'symbol.komunicare.accesories.tie',
        image: '/symbols/komunicare/accesories/corbata.svg',
        id: 'tie',
        backgroundColor: 'rgb(187, 222, 251)'
      }
    ],
    id: 'accesoriesBoard',
    locale: 'es-ES',
    format: 'cboard',
    description: '',
    isFixed: true,
    nameKey: 'symbol.komunicare.accesories',
    author: 'Komunicare',
    email: 'info@komuni.care',
    caption: '/symbols/komunicare/cover/accesorios.svg',
    grid: {
      rows: 3,
      columns: 3,
      order: [
        ['sunCap', 'sunglasses', 'belt'],
        ['gloves', 'glasses', 'wristwatch'],
        ['scarf', 'woolHat', 'tie']
      ]
    }
  }
};
