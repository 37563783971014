export const breakfastBoard = {
  id: 'breakfastsBoard',
  tile: {
    label: 'Desayunos y Meriendas',
    labelKey: 'symbol.komunicare.food.breakfasts',
    image: '/symbols/komunicare/cover/desayunos_meriendas.svg',
    loadBoard: 'breakfastsBoard',
    sound: '',
    type: 'folder',
    backgroundColor: '#2196F3',
    linkedBoard: false,
    id: 'breakfasts'
  },
  board: {
    isPublic: false,
    tiles: [
      {
        label: 'yogur',
        labelKey: 'symbol.komunicare.food.breakfasts.yogurt',
        image: '/symbols/komunicare/breakfast/yogur.svg',
        id: 'yogurt',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'torta',
        labelKey: 'symbol.komunicare.food.breakfasts.cake',
        image: '/symbols/komunicare/breakfast/torta.svg',
        id: 'cake',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'te',
        labelKey: 'symbol.komunicare.food.breakfasts.te',
        image: '/symbols/komunicare/drinks/te.svg',
        id: 'tea',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'submarino',
        labelKey: 'symbol.komunicare.food.breakfasts.submarine',
        image: '/symbols/komunicare/drinks/submarino.svg',
        id: 'submarine',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'mate',
        labelKey: 'symbol.komunicare.food.breakfasts.mate',
        image: '/symbols/komunicare/breakfast/mate.svg',
        id: 'mate',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'mate cocido',
        labelKey: 'symbol.komunicare.food.breakfasts.mateCocido',
        image: '/symbols/komunicare/breakfast/mate_cocido.svg',
        id: 'mateCocido',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'limonada',
        labelKey: 'symbol.komunicare.food.breakfasts.lemonade',
        image: '/symbols/komunicare/drinks/limonada.svg',
        id: 'lemonade',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'licuado',
        labelKey: 'symbol.komunicare.food.breakfasts.smoothie',
        image: '/symbols/komunicare/drinks/licuado.svg',
        id: 'smoothie',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'leche',
        labelKey: 'symbol.komunicare.food.breakfasts.milk',
        image: '/symbols/komunicare/drinks/leche.svg',
        id: 'milk',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'jugo de frutas',
        labelKey: 'symbol.komunicare.food.breakfasts.fruitJuice',
        image: '/symbols/komunicare/drinks/jugo_de_frutas.svg',
        id: 'fruitJuice',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'jugo de naranja',
        labelKey: 'symbol.komunicare.food.breakfasts.orangeJuice',
        image: '/symbols/komunicare/drinks/jugo_de_naranja.svg',
        id: 'orangeJuice',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'helado',
        labelKey: 'symbol.komunicare.food.breakfasts.icecream',
        image: '/symbols/komunicare/breakfast/helado.svg',
        id: 'icecream',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'galletitas saladas',
        labelKey: 'symbol.komunicare.food.breakfasts.crackers',
        image: '/symbols/komunicare/breakfast/galletitas_saladas.svg',
        id: 'crackers',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'galletitas dulces',
        labelKey: 'symbol.komunicare.food.breakfasts.cookies',
        image: '/symbols/komunicare/breakfast/galletitas_dulces.svg',
        id: 'cookies',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'frutos secos',
        labelKey: 'symbol.komunicare.food.breakfasts.driedFruits',
        image: '/symbols/komunicare/breakfast/frutos_secos.svg',
        id: 'driedFruits',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'facturas',
        labelKey: 'symbol.komunicare.food.breakfasts.facturas',
        image: '/symbols/komunicare/breakfast/facturas.svg',
        id: 'facturas',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'chocolatada',
        labelKey: 'symbol.komunicare.food.breakfasts.chocolateMilk',
        image: '/symbols/komunicare/drinks/chocolatada.svg',
        id: 'chocolateMilk',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'cereales',
        labelKey: 'symbol.komunicare.food.breakfasts.cereals',
        image: '/symbols/komunicare/breakfast/cereales.svg',
        id: 'cereals',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'café',
        labelKey: 'symbol.komunicare.food.breakfasts.coffee',
        image: '/symbols/komunicare/drinks/cafe.svg',
        id: 'coffee',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'bizcochuelo',
        labelKey: 'symbol.komunicare.food.breakfasts.spongeCake',
        image: '/symbols/komunicare/breakfast/bizcochuelo.svg',
        id: 'spongeCake',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'agua',
        labelKey: 'symbol.komunicare.food.breakfasts.water',
        image: '/symbols/komunicare/drinks/agua.svg',
        id: 'water',
        backgroundColor: 'rgb(187, 222, 251)'
      }
    ],
    id: 'breakfastsBoard',
    locale: 'es-ES',
    format: 'cboard',
    description: '',
    isFixed: true,
    nameKey: 'symbol.komunicare.food.breakfast',
    author: 'Komunicare',
    email: 'info@komuni.care',
    caption: '/symbols/komunicare/cover/desayunos_meriendas.svg',
    grid: {
      rows: 4,
      columns: 6,
      order: [
        ['water', 'coffee', 'beer', 'chocolateMilk', 'coke', 'softDrinks'],
        ['fruitJuice', 'orangeJuice', 'milk', 'smoothie', 'soda', 'submarine'],
        ['wine', 'IWant', 'dontLike', null, null, null],
        [null, null, null, null, null, null]
      ]
    }
  }
};
