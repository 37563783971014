import { IkomuninicareBoard } from '../types';

export const toysBoard: IkomuninicareBoard = {
  id: 'toys',
  tile: {
    labelKey: 'symbol.komunicare.toys',
    image: '/symbols/komunicare/cover/juguetes.svg',
    loadBoard: 'toyBoard',
    sound: '',
    type: 'folder',
    backgroundColor: '#2196F3',
    linkedBoard: false,
    id: 'toys'
  },
  board: {
    isPublic: false,
    tiles: [
      {
        label: 'animales',
        labelKey: 'symbol.komunicare.toys.animals',
        image: '/symbols/komunicare/toys/animales.svg',
        id: 'toy1',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'autos',
        labelKey: 'symbol.komunicare.toys.cars',
        image: '/symbols/komunicare/toys/autos.svg',
        id: 'toy2',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'bloques',
        labelKey: 'symbol.komunicare.toys.blocks',
        image: '/symbols/komunicare/toys/bloques.svg',
        id: 'toy3',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'burbujas',
        labelKey: 'symbol.komunicare.toys.bubbles',
        image: '/symbols/komunicare/toys/burbujas.svg',
        id: 'toy4',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'cartas',
        labelKey: 'symbol.komunicare.toys.cards',
        image: '/symbols/komunicare/toys/cartas.svg',
        id: 'toy5',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'cuentos',
        labelKey: 'symbol.komunicare.toys.stories',
        image: '/symbols/komunicare/toys/cuentos.svg',
        id: 'toy6',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'enhebrado',
        labelKey: 'symbol.komunicare.toys.threading',
        image: '/symbols/komunicare/toys/enhebrado.svg',
        id: 'toy7',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'guardar',
        labelKey: 'symbol.komunicare.toys.save',
        image: '/symbols/komunicare/toys/guardar.svg',
        id: 'toy8',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'juegos de mesa',
        labelKey: 'symbol.komunicare.toys.boardGames',
        image: '/symbols/komunicare/toys/juegos_de_mesa.svg',
        id: 'toy9',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'jugar',
        labelKey: 'symbol.komunicare.toys.play',
        image: '/symbols/komunicare/toys/jugar.svg',
        id: 'toy10',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'libros para colorear',
        labelKey: 'symbol.komunicare.toys.coloringBooks',
        image: '/symbols/komunicare/toys/libros_para_colorear.svg',
        id: 'toy11',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'masas',
        labelKey: 'symbol.komunicare.toys.playdough',
        image: '/symbols/komunicare/toys/masas.svg',
        id: 'toy12',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'muñeca',
        labelKey: 'symbol.komunicare.toys.doll',
        image: '/symbols/komunicare/toys/muneca.svg',
        id: 'toy13',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'oso de peluche',
        labelKey: 'symbol.komunicare.toys.teddyBear',
        image: '/symbols/komunicare/toys/oso_de_peluche.svg',
        id: 'toy14',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'pelota',
        labelKey: 'symbol.komunicare.toys.ball',
        image: '/symbols/komunicare/toys/pelota.svg',
        id: 'toy15',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'rompecabezas',
        labelKey: 'symbol.komunicare.toys.puzzle',
        image: '/symbols/komunicare/toys/rompecabezas.svg',
        id: 'toy16',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'stickers',
        labelKey: 'symbol.komunicare.toys.stickers',
        image: '/symbols/komunicare/toys/stickers.svg',
        id: 'toy17',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'teléfono',
        labelKey: 'symbol.komunicare.toys.phone',
        image: '/symbols/komunicare/toys/telefono.svg',
        id: 'toy18',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'títeres',
        labelKey: 'symbol.komunicare.toys.puppets',
        image: '/symbols/komunicare/toys/titeres.svg',
        id: 'toy19',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'yo quiero',
        labelKey: 'symbol.komunicare.toys.iWant',
        image: '/symbols/komunicare/toys/yo_quiero.svg',
        id: 'toy20',
        backgroundColor: 'rgb(187, 222, 251)'
      }
    ],
    id: 'toyBoard',
    locale: 'es-ES',
    format: 'cboard',
    description: '',
    isFixed: true,
    nameKey: 'symbol.komunicare.toysTitle',
    author: 'Komunicare',
    email: 'info@komuni.care',
    caption: '/symbols/komunicare/cover/toys.svg',
    grid: {
      rows: 5,
      columns: 4,
      order: []
    }
  }
};
