import { IkomuninicareBoard } from '../types';

export const sportsBoard: IkomuninicareBoard = {
  id: 'sports',
  tile: {
    labelKey: 'symbol.komunicare.sports',
    image: '/symbols/komunicare/cover/deportes.svg',
    loadBoard: 'sportsBoard',
    sound: '',
    type: 'folder',
    backgroundColor: '#2196F3',
    linkedBoard: false,
    id: 'sports'
  },
  board: {
    isPublic: false,
    tiles: [
      {
        label: 'básquetbol',
        labelKey: 'symbol.komunicare.sports.basketball',
        image: '/symbols/komunicare/deporte/basquet.svg',
        id: 'basketball',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'fútbol',
        labelKey: 'symbol.komunicare.sports.soccer',
        image: '/symbols/komunicare/deporte/futbol.svg',
        id: 'soccer',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'tenis',
        labelKey: 'symbol.komunicare.sports.tennis',
        image: '/symbols/komunicare/deporte/tenis.svg',
        id: 'tennis',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'vóleibol',
        labelKey: 'symbol.komunicare.sports.volleyball',
        image: '/symbols/komunicare/deporte/voley.svg',
        id: 'volleyball',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'natación',
        labelKey: 'symbol.komunicare.sports.swimming',
        image: '/symbols/komunicare/deporte/natacion.svg',
        id: 'swimming',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'ciclismo',
        labelKey: 'symbol.komunicare.sports.cycling',
        image: '/symbols/komunicare/deporte/ciclismo.svg',
        id: 'cycling',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'correr',
        labelKey: 'symbol.komunicare.sports.running',
        image: '/symbols/komunicare/deporte/running.svg',
        id: 'running',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'gimnasia',
        labelKey: 'symbol.komunicare.sports.gymnastics',
        image: '/symbols/komunicare/deporte/gimnasia.svg',
        id: 'gymnastics',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'boxeo',
        labelKey: 'symbol.komunicare.sports.boxing',
        image: '/symbols/komunicare/deporte/boxeo.svg',
        id: 'boxing',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'esquí',
        labelKey: 'symbol.komunicare.sports.skiing',
        image: '/symbols/komunicare/deporte/ski.svg',
        id: 'skiing',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'hokey',
        labelKey: 'symbol.komunicare.sports.hockey',
        image: '/symbols/komunicare/deporte/hockey.svg',
        id: 'golf',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'rugby',
        labelKey: 'symbol.komunicare.sports.rugby',
        image: '/symbols/komunicare/deporte/rugby.svg',
        id: 'rugby',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'pesca',
        labelKey: 'symbol.komunicare.sports.fishing',
        image: '/symbols/komunicare/deporte/pesca.svg',
        id: 'surfing',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'taekwondo',
        labelKey: 'symbol.komunicare.sports.taekwondo',
        image: '/symbols/komunicare/deporte/taekwondo.svg',
        id: 'horseRiding',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'bolos',
        labelKey: 'symbol.komunicare.sports.bowling',
        image: '/symbols/komunicare/deporte/bowling.svg',
        id: 'bowling',
        backgroundColor: 'rgb(187, 222, 251)'
      }
    ],
    id: 'sportsBoard',
    locale: 'es-ES',
    format: 'cboard',
    description: '',
    isFixed: true,
    nameKey: 'symbol.komunicare.sports',
    author: 'Komunicare',
    email: 'info@komuni.care',
    caption: '/symbols/komunicare/cover/deportes.svg',
    grid: {
      rows: 3,
      columns: 5,
      order: [
        ['basketball', 'soccer', 'tennis', 'volleyball', 'swimming'],
        ['cycling', 'running', 'gymnastics', 'boxing', 'skiing'],
        ['hockey', 'rugby', 'fishing', 'taekwondo', 'bowling']
      ]
    }
  }
};
