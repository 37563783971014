export const fruitsAndVegetables = {
  id: 'fruitsAndVegetables',
  tile: {
    label: 'Frutas y Verduras',
    labelKey: 'symbol.komunicare.food.fruitsAndVegetables',
    image: '/symbols/komunicare/cover/frutas_verduras.svg',
    loadBoard: 'fruitsAndVegetablesBoard',
    sound: '',
    type: 'folder',
    backgroundColor: '#2196F3',
    linkedBoard: false,
    id: 'fruitsAndVegetables'
  },
  board: {
    isPublic: false,
    tiles: [
      {
        label: 'ananá',
        labelKey: 'symbol.komunicare.food.fruitsAndVegetables.pineapple',
        image: '/symbols/komunicare/fruitsAndVegetables/anana.svg',
        id: 'pineapple',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'arándanos',
        labelKey: 'symbol.komunicare.food.fruitsAndVegetables.blueberries',
        image: '/symbols/komunicare/fruitsAndVegetables/arandanos.svg',
        id: 'blueberries',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'bananas',
        labelKey: 'symbol.komunicare.food.fruitsAndVegetables.bananas',
        image: '/symbols/komunicare/fruitsAndVegetables/bananas.svg',
        id: 'bananas',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'batata',
        labelKey: 'symbol.komunicare.food.fruitsAndVegetables.sweetPotato',
        image: '/symbols/komunicare/fruitsAndVegetables/batata.svg',
        id: 'sweetPotato',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'berenjena',
        labelKey: 'symbol.komunicare.food.fruitsAndVegetables.eggplant',
        image: '/symbols/komunicare/fruitsAndVegetables/berenjena.svg',
        id: 'eggplant',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'brócoli',
        labelKey: 'symbol.komunicare.food.fruitsAndVegetables.broccoli',
        image: '/symbols/komunicare/fruitsAndVegetables/brocoli.svg',
        id: 'broccoli',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'cebolla',
        labelKey: 'symbol.komunicare.food.fruitsAndVegetables.onion',
        image: '/symbols/komunicare/fruitsAndVegetables/cebolla.svg',
        id: 'onion',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'cebollita de verdeo',
        labelKey: 'symbol.komunicare.food.fruitsAndVegetables.greenOnion',
        image:
          '/symbols/komunicare/fruitsAndVegetables/cebollita_de_verdeo.svg',
        id: 'greenOnion',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'cerezas',
        labelKey: 'symbol.komunicare.food.fruitsAndVegetables.cherry',
        image: '/symbols/komunicare/fruitsAndVegetables/cerezas.svg',
        id: 'cherry',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'chauchas',
        labelKey: 'symbol.komunicare.food.fruitsAndVegetables.greenBean',
        image: '/symbols/komunicare/fruitsAndVegetables/chauchas.svg',
        id: 'greenBean',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'choclo',
        labelKey: 'symbol.komunicare.food.fruitsAndVegetables.corn',
        image: '/symbols/komunicare/fruitsAndVegetables/choclo.svg',
        id: 'corn',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'ciruela',
        labelKey: 'symbol.komunicare.food.fruitsAndVegetables.plum',
        image: '/symbols/komunicare/fruitsAndVegetables/ciruela.svg',
        id: 'plum',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'durazno',
        labelKey: 'symbol.komunicare.food.fruitsAndVegetables.peach',
        image: '/symbols/komunicare/fruitsAndVegetables/durazno.svg',
        id: 'peach',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'ensalada',
        labelKey: 'symbol.komunicare.food.fruitsAndVegetables.salad',
        image: '/symbols/komunicare/fruitsAndVegetables/ensalada.svg',
        id: 'salad',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'espárragos',
        labelKey: 'symbol.komunicare.food.fruitsAndVegetables.asparagus',
        image: '/symbols/komunicare/fruitsAndVegetables/esparragos.svg',
        id: 'asparagus',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'espinaca',
        labelKey: 'symbol.komunicare.food.fruitsAndVegetables.spinach',
        image: '/symbols/komunicare/fruitsAndVegetables/espinaca.svg',
        id: 'spinach',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'frutillas',
        labelKey: 'symbol.komunicare.food.fruitsAndVegetables.strawberries',
        image: '/symbols/komunicare/fruitsAndVegetables/frutillas.svg',
        id: 'strawberries',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'kiwi',
        labelKey: 'symbol.komunicare.food.fruitsAndVegetables.kiwi',
        image: '/symbols/komunicare/fruitsAndVegetables/kiwi.svg',
        id: 'kiwi',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'lechuga',
        labelKey: 'symbol.komunicare.food.fruitsAndVegetables.lettuce',
        image: '/symbols/komunicare/fruitsAndVegetables/lechuga.svg',
        id: 'lettuce',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'mandarina',
        labelKey: 'symbol.komunicare.food.fruitsAndVegetables.tangerine',
        image: '/symbols/komunicare/fruitsAndVegetables/mandarina.svg',
        id: 'tangerine',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'manzana',
        labelKey: 'symbol.komunicare.food.fruitsAndVegetables.apple',
        image: '/symbols/komunicare/fruitsAndVegetables/manzana.svg',
        id: 'apple',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'melón',
        labelKey: 'symbol.komunicare.food.fruitsAndVegetables.melon',
        image: '/symbols/komunicare/fruitsAndVegetables/melon.svg',
        id: 'melon',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'morrón',
        labelKey: 'symbol.komunicare.food.fruitsAndVegetables.redPepper',
        image: '/symbols/komunicare/fruitsAndVegetables/morron.svg',
        id: 'redPepper',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'naranja',
        labelKey: 'symbol.komunicare.food.fruitsAndVegetables.orange',
        image: '/symbols/komunicare/fruitsAndVegetables/naranja.svg',
        id: 'orange',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'papa',
        labelKey: 'symbol.komunicare.food.fruitsAndVegetables.potato',
        image: '/symbols/komunicare/fruitsAndVegetables/papa.svg',
        id: 'potato',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'pepino',
        labelKey: 'symbol.komunicare.food.fruitsAndVegetables.cucumber',
        image: '/symbols/komunicare/fruitsAndVegetables/pepino.svg',
        id: 'cucumber',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'pera',
        labelKey: 'symbol.komunicare.food.fruitsAndVegetables.pear',
        image: '/symbols/komunicare/fruitsAndVegetables/pera.svg',
        id: 'pear',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'pomelo',
        labelKey: 'symbol.komunicare.food.fruitsAndVegetables.grapefruit',
        image: '/symbols/komunicare/fruitsAndVegetables/pomelo.svg',
        id: 'grapefruit',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'rabanito',
        labelKey: 'symbol.komunicare.food.fruitsAndVegetables.radish',
        image: '/symbols/komunicare/fruitsAndVegetables/rabanito.svg',
        id: 'radish',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'repollo',
        labelKey: 'symbol.komunicare.food.fruitsAndVegetables.cabbage',
        image: '/symbols/komunicare/fruitsAndVegetables/repollo.svg',
        id: 'cabbage',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'sandía',
        labelKey: 'symbol.komunicare.food.fruitsAndVegetables.watermelon',
        image: '/symbols/komunicare/fruitsAndVegetables/sandia.svg',
        id: 'watermelon',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'tomate',
        labelKey: 'symbol.komunicare.food.fruitsAndVegetables.tomato',
        image: '/symbols/komunicare/fruitsAndVegetables/tomate.svg',
        id: 'tomato',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'uvas',
        labelKey: 'symbol.komunicare.food.fruitsAndVegetables.grapes',
        image: '/symbols/komunicare/fruitsAndVegetables/uvas.svg',
        id: 'grapes',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'zanahoria',
        labelKey: 'symbol.komunicare.food.fruitsAndVegetables.carrot',
        image: '/symbols/komunicare/fruitsAndVegetables/zanahoria.svg',
        id: 'carrot',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'zapallo',
        labelKey: 'symbol.komunicare.food.fruitsAndVegetables.pumpkin',
        image: '/symbols/komunicare/fruitsAndVegetables/zapallo.svg',
        id: 'pumpkin',
        backgroundColor: 'rgb(187, 222, 251)'
      }
    ],
    id: 'fruitsAndVegetablesBoard',
    locale: 'es-ES',
    format: 'cboard',
    description: '',
    isFixed: true,
    nameKey: 'symbol.komunicare.food.fruitsAndVegetables',
    author: 'Komunicare',
    email: 'info@komuni.care',
    caption: '/symbols/komunicare/cover/frutas_vegetales.svg',
    grid: {
      rows: 5,
      columns: 7,
      order: [
        [
          'pineapple',
          'blueberries',
          'bananas',
          'sweetPotato',
          'eggplant',
          'broccoli',
          'watermelon'
        ],
        [
          'onion',
          'greenOnion',
          'cherry',
          'greenBean',
          'corn',
          'plum',
          'tomato'
        ],
        [
          'peach',
          'salad',
          'asparagus',
          'spinach',
          'strawberries',
          'kiwi',
          'grapes'
        ],
        [
          'lettuce',
          'tangerine',
          'apple',
          'melon',
          'redPepper',
          'orange',
          'carrot'
        ],
        [
          'potato',
          'cucumber',
          'pear',
          'grapefruit',
          'radish',
          'cabbage',
          'pumpkin'
        ]
      ]
    }
  }
};
