import { defineMessages, MessageDescriptor } from 'react-intl';

interface Messages {
  [key: string]: MessageDescriptor;
}

const messages: Messages = defineMessages({
  save: {
    id: 'cboard.components.App.save',
    defaultMessage: 'Save'
  },
  newContentAvailable: {
    id: 'cboard.components.App.newContentAvailable',
    defaultMessage: 'New content is available; please refresh.'
  },
  contentIsCached: {
    id: 'cboard.components.App.contentIsCached',
    defaultMessage: 'Content is cached for offline use.'
  }
});

export default messages;
