import { IkomuninicareBoard } from '../types';

export const questionsAndConversationBoard: IkomuninicareBoard = {
  id: 'questionsAndConversation',
  tile: {
    labelKey: 'symbol.komunicare.questionsAndConversation',
    image: '/symbols/komunicare/questions/que.svg',
    loadBoard: 'questionsAndConversationBoard',
    sound: '',
    type: 'folder',
    backgroundColor: '#2196F3',
    linkedBoard: false,
    id: 'questionsAndConversation'
  },
  board: {
    isPublic: true,
    tiles: [
      {
        id: 'yesterday',
        labelKey: 'symbol.komunicare.questionsAndConversation.yesterday',
        image: '/symbols/komunicare/questions/ayer.svg',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        id: 'bye',
        labelKey: 'symbol.komunicare.questionsAndConversation.bye',
        image: '/symbols/komunicare/questions/chau.svg',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        id: 'how',
        labelKey: 'symbol.komunicare.questionsAndConversation.how',
        image: '/symbols/komunicare/questions/como.svg',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        id: 'howIs',
        labelKey: 'symbol.komunicare.questionsAndConversation.howIs',
        image: '/symbols/komunicare/questions/como_es.svg',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        id: 'howAreYou',
        labelKey: 'symbol.komunicare.questionsAndConversation.howAreYou',
        image: '/symbols/komunicare/questions/como_estas.svg',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        id: 'which',
        labelKey: 'symbol.komunicare.questionsAndConversation.which',
        image: '/symbols/komunicare/questions/cual.svg',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        id: 'when',
        labelKey: 'symbol.komunicare.questionsAndConversation.when',
        image: '/symbols/komunicare/questions/cuando.svg',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        id: 'howMany',
        labelKey: 'symbol.komunicare.questionsAndConversation.howMany',
        image: '/symbols/komunicare/questions/cuantos.svg',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        id: 'where',
        labelKey: 'symbol.komunicare.questionsAndConversation.where',
        image: '/symbols/komunicare/questions/donde.svg',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        id: 'where2',
        labelKey: 'symbol.komunicare.questionsAndConversation.where2',
        image: '/symbols/komunicare/questions/donde_2.svg',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        id: 'thanks',
        labelKey: 'symbol.komunicare.questionsAndConversation.thanks',
        image: '/symbols/komunicare/questions/gracias.svg',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        id: 'hello',
        labelKey: 'symbol.komunicare.questionsAndConversation.hello',
        image: '/symbols/komunicare/questions/hola.svg',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        id: 'today',
        labelKey: 'symbol.komunicare.questionsAndConversation.today',
        image: '/symbols/komunicare/questions/hoy.svg',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        id: 'tomorrow',
        labelKey: 'symbol.komunicare.questionsAndConversation.tomorrow',
        image: '/symbols/komunicare/questions/mañana.svg',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        id: 'no',
        labelKey: 'symbol.komunicare.questionsAndConversation.no',
        image: '/symbols/komunicare/questions/no.svg',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        id: 'cantSpeak',
        labelKey: 'symbol.komunicare.questionsAndConversation.cantSpeak',
        image: '/symbols/komunicare/questions/no_puedo_hablar.svg',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        id: 'please',
        labelKey: 'symbol.komunicare.questionsAndConversation.please',
        image: '/symbols/komunicare/questions/por_favor.svg',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        id: 'why',
        labelKey: 'symbol.komunicare.questionsAndConversation.why',
        image: '/symbols/komunicare/questions/por_que.svg',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        id: 'what',
        labelKey: 'symbol.komunicare.questionsAndConversation.what',
        image: '/symbols/komunicare/questions/que.svg',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        id: 'whatIs',
        labelKey: 'symbol.komunicare.questionsAndConversation.whatIs',
        image: '/symbols/komunicare/questions/que_es.svg',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        id: 'whoIs',
        labelKey: 'symbol.komunicare.questionsAndConversation.whoIs',
        image: '/symbols/komunicare/questions/quien_es.svg',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        id: 'yes',
        labelKey: 'symbol.komunicare.questionsAndConversation.yes',
        image: '/symbols/komunicare/questions/si.svg',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        id: 'iTellYou',
        labelKey: 'symbol.komunicare.questionsAndConversation.iTellYou',
        image: '/symbols/komunicare/questions/te_lo_cuento_yo.svg',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        id: 'iWantToAskYou',
        labelKey: 'symbol.komunicare.questionsAndConversation.iWantToAskYou',
        image: '/symbols/komunicare/questions/te_quiero_preguntar.svg',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        id: 'allGood',
        labelKey: 'symbol.komunicare.questionsAndConversation.allGood',
        image: '/symbols/komunicare/questions/todo_bien.svg',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        id: 'allBad',
        labelKey: 'symbol.komunicare.questionsAndConversation.allBad',
        image: '/symbols/komunicare/questions/todo_mal.svg',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        id: 'letsGo',
        labelKey: 'symbol.komunicare.questionsAndConversation.letsGo',
        image: '/symbols/komunicare/questions/vamos.svg',
        backgroundColor: 'rgb(187, 222, 251)'
      }
    ],
    id: 'questionsAndConversationBoard',
    locale: 'es-ES',
    format: 'cboard',
    description: 'Preguntas y conversación',
    isFixed: true,
    nameKey: 'symbol.komunicare.questionsAndConversation',
    author: 'Komunicare',
    email: 'info@komuni.care',
    caption: '/symbols/komunicare/cover/questionsAndConversation.svg',
    grid: {
      rows: 5,
      columns: 6,
      order: [
        ['yesterday', 'bye', 'how', 'howIs', 'howAreYou', 'which'],
        ['when', 'howMany', 'where', 'where2', 'thanks', 'hello'],
        ['today', 'tomorrow', 'no', 'cantSpeak', 'please', 'why'],
        ['what', 'whatIs', 'whoIs', 'yes', 'iTellYou', 'iWantToAskYou'],
        ['allGood', 'allBad', 'letsGo', null, null, null]
      ]
    }
  }
};
