interface DefaultBoardIncluded {
  nameOnJSON: string;
  homeBoard: string;
}

export interface Communicator {
  id: string;
  name: string;
  description: string;
  author: string;
  email: string;
  rootBoard: string;
  boards: string[];
  defaultBoardsIncluded: DefaultBoardIncluded[];
}


export const defaultCommunicators: Communicator[] = [
  {
    id: "cboard_default",
    name: "Cboard's Communicator",
    description: "Cboard's default communicator",
    author: "Cboard Team",
    email: "support@cboard.io",
    rootBoard: "root",
    boards: ["root"],
    defaultBoardsIncluded: [
      { nameOnJSON: "advanced", homeBoard: "root" },
      { nameOnJSON: "picSeePal", homeBoard: "jjmlUcQs19" },
      { nameOnJSON: "komunicare", homeBoard: "komunicare" },
    ],
  },
];
