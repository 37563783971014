export const familyBoard = {
  id: 'family',
  tile: {
    labelKey: 'symbol.komunicare.family',
    image: '/symbols/komunicare/cover/familia.svg',
    loadBoard: 'familyBoard',
    sound: '',
    type: 'folder',
    backgroundColor: '#2196F3',
    linkedBoard: false,
    id: 'family'
  },
  board: {
    isPublic: false,
    tiles: [
      {
        label: 'abuela',
        labelKey: 'symbol.komunicare.family.grandmother',
        image: '/symbols/komunicare/family/abuela.svg',
        id: 'grandmother',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'abuelo',
        labelKey: 'symbol.komunicare.family.grandfather',
        image: '/symbols/komunicare/family/abuelo.svg',
        id: 'grandfather',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'bebé',
        labelKey: 'symbol.komunicare.family.baby',
        image: '/symbols/komunicare/family/bebe.svg',
        id: 'baby',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'hermana',
        labelKey: 'symbol.komunicare.family.sister',
        image: '/symbols/komunicare/family/hermana.svg',
        id: 'sister',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'hermano',
        labelKey: 'symbol.komunicare.family.brother',
        image: '/symbols/komunicare/family/hermano.svg',
        id: 'brother',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'hija',
        labelKey: 'symbol.komunicare.family.daughter',
        image: '/symbols/komunicare/family/hija.svg',
        id: 'daughter',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'hijo',
        labelKey: 'symbol.komunicare.family.son',
        image: '/symbols/komunicare/family/hijo.svg',
        id: 'son',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'madre',
        labelKey: 'symbol.komunicare.family.mother',
        image: '/symbols/komunicare/family/madre.svg',
        id: 'mother',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'padre',
        labelKey: 'symbol.komunicare.family.father',
        image: '/symbols/komunicare/family/padre.svg',
        id: 'father',
        backgroundColor: 'rgb(187, 222, 251)'
      }
    ],
    id: 'familyBoard',
    locale: 'es-ES',
    format: 'cboard',
    description: '',
    isFixed: true,
    nameKey: 'symbol.komunicare.family',
    author: 'Komunicare',
    email: 'info@komuni.care',
    caption: '/symbols/komunicare/cover/familia.svg',
    grid: {
      rows: 3,
      columns: 3,
      order: [
        ['grandmother', 'grandfather', 'sister'],
        ['brother', 'daughter', 'son'],
        ['mother', 'father', 'baby']
      ]
    }
  }
};
