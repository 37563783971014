import React, { FC, ReactNode } from 'react';
// @ts-ignore
import { FormattedMessage } from 'react-intl';

import messages from './Information.messages';

interface InformationProps {
  heading?: ReactNode;
  text?: any;
}

const Information: FC<InformationProps> = ({ heading }) => (
  <>
    <h2 style={{ textAlign: 'center' }} className="WelcomeScreen__heading">
      {heading ? heading : <FormattedMessage {...messages.heading} />}
    </h2>
  </>
);

export default Information;
