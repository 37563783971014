import { accesoriesBoard } from './accesoriesBoard';

export const clothingBoard = {
  id: 'clothingBoard',
  tile: {
    labelKey: 'symbol.komunicare.clothing',
    image: '/symbols/komunicare/cover/ropa.svg',
    loadBoard: 'clothingBoard',
    sound: '',
    type: 'folder',
    backgroundColor: '#2196F3',
    linkedBoard: false,
    id: 'clothing'
  },
  board: {
    isPublic: false,
    tiles: [
      accesoriesBoard.tile,
      {
        label: 'botas de lluvia',
        labelKey: 'symbol.komunicare.clothing.rainBoots',
        image: '/symbols/komunicare/clothing/botas_de_lluvia.svg',
        id: 'rainBoots',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'buzo',
        labelKey: 'symbol.komunicare.clothing.sweatshirt',
        image: '/symbols/komunicare/clothing/buzo.svg',
        id: 'sweatshirt',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'calzoncillo',
        labelKey: 'symbol.komunicare.clothing.underwear',
        image: '/symbols/komunicare/clothing/calzoncillo.svg',
        id: 'underwear',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'short',
        labelKey: 'symbol.komunicare.clothing.shorts',
        image: '/symbols/komunicare/clothing/short.svg',
        id: 'shorts',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'zapatos mujer',
        labelKey: 'symbol.komunicare.clothing.womenShoes',
        image: '/symbols/komunicare/clothing/zapatos mujer.svg',
        id: 'womenShoes',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'ojotas',
        labelKey: 'symbol.komunicare.clothing.flipFlops',
        image: '/symbols/komunicare/clothing/ojotas.svg',
        id: 'flipFlops',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'musculosa',
        labelKey: 'symbol.komunicare.clothing.tankTop',
        image: '/symbols/komunicare/clothing/musculosa.svg',
        id: 'tankTop',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'sandalias',
        labelKey: 'symbol.komunicare.clothing.sandals',
        image: '/symbols/komunicare/clothing/sandalias.svg',
        id: 'sandals',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'pijama',
        labelKey: 'symbol.komunicare.clothing.pajamas',
        image: '/symbols/komunicare/clothing/pijama.svg',
        id: 'pajamas',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'campera',
        labelKey: 'symbol.komunicare.clothing.jacket',
        image: '/symbols/komunicare/clothing/campera.svg',
        id: 'jacket',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'remera mangas largas',
        labelKey: 'symbol.komunicare.clothing.longSleeveShirt',
        image: '/symbols/komunicare/clothing/remera_mangas_largas.svg',
        id: 'longSleeveShirt',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'camisa',
        labelKey: 'symbol.komunicare.clothing.shirt',
        image: '/symbols/komunicare/clothing/camisa.svg',
        id: 'shirt',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'vestido',
        labelKey: 'symbol.komunicare.clothing.dress',
        image: '/symbols/komunicare/clothing/vestido.svg',
        id: 'dress',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'bombacha',
        labelKey: 'symbol.komunicare.clothing.panties',
        image: '/symbols/komunicare/clothing/bombacha.svg',
        id: 'panties',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'corpiño',
        labelKey: 'symbol.komunicare.clothing.bra',
        image: '/symbols/komunicare/clothing/corpino.svg',
        id: 'bra',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'sweater',
        labelKey: 'symbol.komunicare.clothing.sweater',
        image: '/symbols/komunicare/clothing/sweater.svg',
        id: 'sweater',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'zapatos hombre',
        labelKey: 'symbol.komunicare.clothing.menShoes',
        image: '/symbols/komunicare/clothing/zapatos_hombre.svg',
        id: 'menShoes',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'remera',
        labelKey: 'symbol.komunicare.clothing.tShirt',
        image: '/symbols/komunicare/clothing/remera.svg',
        id: 'tShirt',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'pantuflas',
        labelKey: 'symbol.komunicare.clothing.slippers',
        image: '/symbols/komunicare/clothing/pantuflas.svg',
        id: 'slippers',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'pantalón',
        labelKey: 'symbol.komunicare.clothing.pants',
        image: '/symbols/komunicare/clothing/pantalon.svg',
        id: 'pants',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'medias',
        labelKey: 'symbol.komunicare.clothing.socks',
        image: '/symbols/komunicare/clothing/medias.svg',
        id: 'socks',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'pollera',
        labelKey: 'symbol.komunicare.clothing.skirt',
        image: '/symbols/komunicare/clothing/pollera.svg',
        id: 'skirt',
        backgroundColor: 'rgb(187, 222, 251)'
      }
    ],
    id: 'clothingBoard',
    locale: 'es-ES',
    format: 'cboard',
    description: '',
    isFixed: true,
    nameKey: 'symbol.komunicare.clothing',
    author: 'Komunicare',
    email: 'info@komuni.care',
    caption: '/symbols/komunicare/cover/ropa.svg',
    grid: {
      rows: 5,
      columns: 5,
      order: []
    }
  }
};
