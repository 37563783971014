export const chatBoard = {
  id: 'chat',
  tile: {
    labelKey: 'symbol.komunicare.chatTitle',
    image: '/symbols/komunicare/cover/frases.svg',
    loadBoard: 'chatBoard',
    sound: '',
    type: 'folder',
    backgroundColor: '#2196F3',
    linkedBoard: false,
    id: 'chat'
  },
  board: {
    isPublic: false,
    tiles: [
      {
        label: 'me duele',
        labelKey: 'symbol.komunicare.chat.hurt',
        image: '/symbols/komunicare/chat/me_duele.svg',
        id: 'hurt',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'me quiero dar vuelta',
        labelKey: 'symbol.komunicare.chat.turn',
        image: '/symbols/komunicare/chat/me_quiero_dar_vuelta.svg',
        id: 'turn',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'me quiero sentar',
        labelKey: 'symbol.komunicare.chat.sit',
        image: '/symbols/komunicare/chat/me_quiero_sentar.svg',
        id: 'sit',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'quiero ir al baño',
        labelKey: 'symbol.komunicare.chat.bathroom',
        image: '/symbols/komunicare/chat/quiero_ir_al_bano.svg',
        id: 'bathroom',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'tengo sed',
        labelKey: 'symbol.komunicare.chat.thirsty',
        image: '/symbols/komunicare/chat/tengo_sed.svg',
        id: 'thirsty',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'tengo hambre',
        labelKey: 'symbol.komunicare.chat.hungry',
        image: '/symbols/komunicare/chat/tengo_hambre.svg',
        id: 'hungry',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'me pica la espalda',
        labelKey: 'symbol.komunicare.chat.itches',
        image: '/symbols/komunicare/chat/me_pica_la_espalda.svg',
        id: 'itches',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'me siento mal, ¿Podés venír?',
        labelKey: 'symbol.komunicare.chat.feelingBad',
        image: '/symbols/komunicare/chat/me_siento_mal_podes_venir.svg',
        id: 'feelingBad',
        backgroundColor: 'rgb(187, 222, 251)'
      }
    ],
    id: 'chatBoard',
    locale: 'es-ES',
    format: 'cboard',
    description: '',
    isFixed: true,
    nameKey: 'symbol.komunicare.chatTitle',
    author: 'Komunicare',
    email: 'info@komuni.care',
    caption: '/symbols/komunicare/cover/frases.svg',
    grid: {
      rows: 2,
      columns: 4,
      order: [
        ['hurt', 'turn', 'sit', 'bathroom'],
        ['thirsty', 'hungry', 'itches', 'feelingBad']
      ]
    }
  }
};
