import { IkomuninicareBoard } from '../types';

export const actionsBoard: IkomuninicareBoard = {
  id: 'actions',
  tile: {
    labelKey: 'symbol.komunicare.actions',
    image: '/symbols/komunicare/cover/acciones.svg',
    loadBoard: 'actionBoard',
    sound: '',
    type: 'folder',
    backgroundColor: '#2196F3',
    linkedBoard: false,
    id: 'actions'
  },
  board: {
    isPublic: false,
    tiles: [
      {
        label: 'abrir',
        labelKey: 'symbol.komunicare.actions.open',
        image: '/symbols/komunicare/actions/abrir.svg',
        id: 'action1',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'agarrar',
        labelKey: 'symbol.komunicare.actions.grab',
        image: '/symbols/komunicare/actions/agarrar.svg',
        id: 'action2',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'comer',
        labelKey: 'symbol.komunicare.actions.eat',
        image: '/symbols/komunicare/actions/comer.svg',
        id: 'action3',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'contar',
        labelKey: 'symbol.komunicare.actions.count',
        image: '/symbols/komunicare/actions/contar.svg',
        id: 'action4',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'dar',
        labelKey: 'symbol.komunicare.actions.give',
        image: '/symbols/komunicare/actions/dar.svg',
        id: 'action5',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'dibujar',
        labelKey: 'symbol.komunicare.actions.draw',
        image: '/symbols/komunicare/actions/dibujar.svg',
        id: 'action6',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'dormir',
        labelKey: 'symbol.komunicare.actions.sleep',
        image: '/symbols/komunicare/actions/dormir.svg',
        id: 'action7',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'escribir',
        labelKey: 'symbol.komunicare.actions.write',
        image: '/symbols/komunicare/actions/escribir.svg',
        id: 'action8',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'escuchar',
        labelKey: 'symbol.komunicare.actions.listen',
        image: '/symbols/komunicare/actions/escuchar.svg',
        id: 'action9',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'esperar',
        labelKey: 'symbol.komunicare.actions.wait',
        image: '/symbols/komunicare/actions/esperar.svg',
        id: 'action10',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'ir',
        labelKey: 'symbol.komunicare.actions.go',
        image: '/symbols/komunicare/actions/ir.svg',
        id: 'action11',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'jugar',
        labelKey: 'symbol.komunicare.actions.play',
        image: '/symbols/komunicare/actions/jugar_accion.svg',
        id: 'action12',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'mirar',
        labelKey: 'symbol.komunicare.actions.look',
        image: '/symbols/komunicare/actions/mirar.svg',
        id: 'action13',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'pintar',
        labelKey: 'symbol.komunicare.actions.paint',
        image: '/symbols/komunicare/actions/pintar.svg',
        id: 'action14',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'saltar',
        labelKey: 'symbol.komunicare.actions.jump',
        image: '/symbols/komunicare/actions/saltar.svg',
        id: 'action15',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'trabajar',
        labelKey: 'symbol.komunicare.actions.work',
        image: '/symbols/komunicare/actions/trabajar_en_mesa.svg',
        id: 'action16',
        backgroundColor: 'rgb(187, 222, 251)'
      }
    ],
    id: 'actionBoard',
    locale: 'es-ES',
    format: 'cboard',
    description: '',
    isFixed: true,
    nameKey: 'symbol.komunicare.actionsTitle',
    author: 'Komunicare',
    email: 'info@komuni.care',
    caption: '/symbols/komunicare/cover/actions.svg',
    grid: {
      rows: 4,
      columns: 4,
      order: []
    }
  }
};
