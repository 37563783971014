import { fruitsAndVegetables } from './fruitsAndVegetables';
import { breakfastBoard } from './breakfastsBoard';
export const foodBoard = {
  id: 'food',
  tile: {
    labelKey: 'symbol.komunicare.food',
    image: '/symbols/komunicare/cover/comidas.svg',
    loadBoard: 'foodBoard',
    sound: '',
    type: 'folder',
    backgroundColor: '#2196F3',
    linkedBoard: false,
    id: 'food'
  },
  board: {
    isPublic: false,
    tiles: [
      breakfastBoard.tile,
      fruitsAndVegetables.tile,
      {
        label: 'arroz',
        labelKey: 'symbol.komunicare.food.rice',
        image: '/symbols/komunicare/food/arroz.svg',
        id: 'rice',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'carne',
        labelKey: 'symbol.komunicare.food.meat',
        image: '/symbols/komunicare/food/carne.svg',
        id: 'meat',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'fideos',
        labelKey: 'symbol.komunicare.food.noodles',
        image: '/symbols/komunicare/food/fideos.svg',
        id: 'noodles',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'hamburguesa',
        labelKey: 'symbol.komunicare.food.burger',
        image: '/symbols/komunicare/food/hamburguesa.svg',
        id: 'burger',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'huevo cocido',
        labelKey: 'symbol.komunicare.food.boiledEgg',
        image: '/symbols/komunicare/food/huevo_cocido.svg',
        id: 'boiledEgg',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'huevo frito',
        labelKey: 'symbol.komunicare.food.friedEgg',
        image: '/symbols/komunicare/food/huevo_frito.svg',
        id: 'friedEgg',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'ketchup',
        labelKey: 'symbol.komunicare.food.ketchup',
        image: '/symbols/komunicare/food/ketchup.svg',
        id: 'ketchup',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'mayonesa',
        labelKey: 'symbol.komunicare.food.mayonnaise',
        image: '/symbols/komunicare/food/mayonesa.svg',
        id: 'mayonnaise',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'milanesa',
        labelKey: 'symbol.komunicare.food.milanesa',
        image: '/symbols/komunicare/food/milanesa.svg',
        id: 'milanesa',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'pan',
        labelKey: 'symbol.komunicare.food.bread',
        image: '/symbols/komunicare/food/pan.svg',
        id: 'bread',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'pancho',
        labelKey: 'symbol.komunicare.food.hotdog',
        image: '/symbols/komunicare/food/pancho.svg',
        id: 'hotdog',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'panqueque',
        labelKey: 'symbol.komunicare.food.pancake',
        image: '/symbols/komunicare/food/panqueque.svg',
        id: 'pancake',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'papas fritas',
        labelKey: 'symbol.komunicare.food.fries',
        image: '/symbols/komunicare/food/papas_fritas.svg',
        id: 'fries',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'pescado',
        labelKey: 'symbol.komunicare.food.fish',
        image: '/symbols/komunicare/food/pescado.svg',
        id: 'fish',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'pizza',
        labelKey: 'symbol.komunicare.food.pizza',
        image: '/symbols/komunicare/food/pizza.svg',
        id: 'pizza',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'pollo',
        labelKey: 'symbol.komunicare.food.chicken',
        image: '/symbols/komunicare/food/pollo.svg',
        id: 'chicken',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'pure',
        labelKey: 'symbol.komunicare.food.puree',
        image: '/symbols/komunicare/food/pure.svg',
        id: 'puree',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'queso',
        labelKey: 'symbol.komunicare.food.cheese',
        image: '/symbols/komunicare/food/queso.svg',
        id: 'cheese',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'sal',
        labelKey: 'symbol.komunicare.food.salt',
        image: '/symbols/komunicare/food/sal.svg',
        id: 'salt',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'salsa golf',
        labelKey: 'symbol.komunicare.food.golfSauce',
        image: '/symbols/komunicare/food/salsa_golf.svg',
        id: 'golfSauce',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'sandwich',
        labelKey: 'symbol.komunicare.food.sandwich',
        image: '/symbols/komunicare/food/sandwich.svg',
        id: 'sandwich',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'sopa',
        labelKey: 'symbol.komunicare.food.soup',
        image: '/symbols/komunicare/food/sopa.svg',
        id: 'soup',
        backgroundColor: 'rgb(187, 222, 251)'
      },
      {
        label: 'tarta',
        labelKey: 'symbol.komunicare.food.pie',
        image: '/symbols/komunicare/food/tarta.svg',
        id: 'pie',
        backgroundColor: 'rgb(187, 222, 251)'
      }
    ],
    id: 'foodBoard',
    locale: 'en-US',
    format: 'cboard',
    description: '',
    isFixed: true,
    nameKey: 'symbol.komunicare.food',
    author: 'Komunicare',
    email: 'info@komuni.care',
    caption: '/symbols/komunicare/cover/comidas.svg',
    grid: {
      rows: 5,
      columns: 6,
      order: [
        // ... grid order ...
      ]
    }
  }
};
